@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #0f172a;    /* Deep slate */
  --secondary-color: #334155;  /* Lighter slate */
  --background-color: #f8fafc; /* Light gray blue */
  --text-color: #0f172a;      /* Deep slate */
  --accent-color: #e13aed;    /* Vibrant purple */
  --accent-light: #fa8beb;    /* Light purple */
  --card-background: #ffffff;  /* Pure white */
  --neutral-light: #e2e8f0;   /* Light gray */
  --code-bg: #1e293b;         /* Dark slate for code blocks */
  --section-padding: 3rem;
  --component-spacing: 2rem;
  --container-width: 1200px;
  --nav-height: 80px;
  --box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --gradient-start: #e13aed;
  --gradient-end: #fa8beb;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--text-color);
}

p {
  line-height: 1.8;
  color: var(--text-color);
  opacity: 0.9;
  margin-bottom: 1rem;
}

.section-container {
  padding: var(--section-padding) 4rem;
  max-width: var(--container-width);
  margin: 0 auto;
}

.card {
  background-color: var(--card-background);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease;
  padding: 2rem;
  margin-bottom: var(--component-spacing);
}

.card:hover {
  transform: translateY(-5px);
}

.gradient-text {
  background: linear-gradient(135deg, var(--accent-color), var(--accent-light));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.section-title {
  font-size: 2.8rem;
  margin-bottom: 2.5rem;
  text-align: center;
  background: linear-gradient(45deg, var(--gradient-start), var(--gradient-end));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(to right, var(--primary-color), var(--gradient-end));
  border-radius: 3px;
}

.fun-card {
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--neutral-light);
}

.fun-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  border-color: var(--accent-color);
}

.nav a:hover {
  color: var(--primary-color);
}

/* Add monospace for code elements */
code {
  font-family: 'JetBrains Mono', monospace;
  background-color: var(--code-bg);
  color: #e2e8f0;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-size: 0.9em;
}

/* Update vertical rhythm */
h1 { margin-bottom: 1.5rem; }
h2 { margin-bottom: 1.25rem; }
h3 { margin-bottom: 1rem; }

/* Mobile-specific styles */
@media (max-width: 768px) {
  :root {
    --container-padding: 1.5rem;
  }

  h1 { 
    font-size: 2.8rem !important; 
    line-height: 1.2 !important;
  }
  
  .section-container {
    padding: 2rem var(--container-padding) !important;
  }

  /* Grid layouts */
  .grid-cols-2 {
    grid-template-columns: 1fr !important;
  }

  /* Navigation */
  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 70%;
    background: var(--background-color);
    padding: 2rem;
    transition: 0.3s ease;
    flex-direction: column;
    justify-content: center;
  }

  .nav-links.active {
    right: 0;
  }

  .mobile-menu-btn {
    display: block !important;
  }

  /* Component specific */
  .profile-container {
    flex-direction: column !important;
    gap: 2rem !important;
    text-align: center !important;
  }

  .interests-grid {
    grid-template-columns: 1fr !important;
  }

  .project-grid {
    grid-template-columns: 1fr !important;
  }
}